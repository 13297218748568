(function(){
	"use strict";

	angular.module('app.config').config(function($mdThemingProvider) {
		/* For more info, visit https://material.angularjs.org/#/Theming/01_introduction */
		$mdThemingProvider.theme('default')
            .primaryPalette('indigo', {
                'hue-3': '800'
            })
            .accentPalette('grey')
            .warnPalette('red');
        
        $mdThemingProvider.theme('btn-theme')
            .primaryPalette('green', {
                'default':'700'
            })
            .accentPalette('yellow', {
                'default':'A700'
            })
            .warnPalette('red', {
                'default':'800'
            });
	});

})();
