(function(){
    "use strict";

    angular.module('app.controllers').controller('FormDatabaseController', FormDatabaseController);


    function FormDatabaseController(DialogService, $scope, $log){
        
        var vm = this;
        
        $log.log($scope);

        vm.save = function(){
            //
        };

        vm.hide = function(){
            DialogService.hide();
        };

    }

})();
