(function(){
    "use strict";

    angular.module('app.services').factory('FtpService', FtpService);

    function FtpService(API){
        return API.one('ftp');
    }

})();
