(function() {
	"use strict";

	angular.module('app.controllers').controller('CriarUserController', CriarUserController);

	function CriarUserController() {
		var vm = this;

		vm.angular_description = 'Preencha todos os campos abaixo';
	}

})();