(function(){
	"use strict";

	angular.module('app', [
		'app.controllers', 'app.filters', 'app.services', 'app.directives',
		'app.routes', 'app.config', 'app.run', 'partialsModule'
    ]);

	angular.module('app.routes', []);
    angular.module('app.run', []);
	angular.module('app.controllers', [
        'ui.router', 'ngMaterial', 'ngStorage', 'restangular', 
        'angular-loading-bar', 'md.data.table', 'ngclipboard', 'ngMessages'
    ]);
	angular.module('app.filters', []);
	angular.module('app.services', []);
	angular.module('app.directives', []);
	angular.module('app.config', []);

})();
