(function() {
	"use strict";

	angular.module('app.controllers').controller('LoginController', LoginController);

	function LoginController(AuthService, $localStorage, $state, ToastService) {
		var vm = this;

		vm.angular_description = 'Digite seu login e senha para acessar o sistema!';

        vm.login = {email:'', password:''};
        vm.entrar = entrar;

        function entrar(){
            AuthService.auth.post('login',vm.login).then(function(res){
                $localStorage.jwt = res.data.token;
                AuthService.auth.post('getUser').then(function(suc){
                    $localStorage.userAuth = suc.user;
                    return $state.go('app.landing');
                }, function(){
                    delete $localStorage.jwt;
                    return ToastService.show('Não foi possível consultar usuário, tente fazer login novamente!');
                })
            }, function(err){
                return ToastService.error(err.data.error);
            });
        }

	}

})();
