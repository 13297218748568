(function() {
	"use strict";

	angular.module('app.controllers').controller('UserController', UserController);

	function UserController(UsersService, $log, $mdDialog, ToastService, $localStorage) {
		var vm = this;

		vm.angular_description = 'Altere seus dados pessoais.';
        vm.user = $localStorage.userAuth; 
        
        vm.atualizar = atualizar;
        
        /* FUNCTIONS */
        function atualizar(){
            var data = angular.copy(vm.user);
            UsersService.post('atualizar_usuario', data).then(success);
        }
        
        function success(result) {
            $localStorage.userAuth = result.users[0];
            vm.user = $localStorage.userAuth;
        }
        
    }
})();