(function(){
    "use strict";

    angular.module('app.services').factory('DiversosService', DiversosService);

    function DiversosService(API){
        return API.one('diversos');
    }

})();
