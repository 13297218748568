(function(){
    "use strict";

    angular.module('app.services').factory('UsersService', UsersService);

    function UsersService(API){
        return API.one('users');
    }

})();
