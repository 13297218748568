(function(){
    "use strict";

    angular.module('app.controllers').controller('HeaderController', HeaderController);

    function HeaderController($localStorage, $state){
        var vm = this;

        vm.userAuth = $localStorage.userAuth;

        vm.logoff = logoff;

        /* FUNCTIONS */

        function logoff(){
            $localStorage.$reset();
            return $state.go('auth.login');
        }

    }

})();
