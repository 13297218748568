(function() {
	"use strict";

	angular.module('app.controllers').controller('FtpController', FtpController);

	function FtpController(FtpService, $log, $mdDialog, ToastService, $document) {
		var vm = this;

		vm.angular_description = 'Faça uma busca no campo abaixo para destacar o que deseja.';

        vm.selected = [];
        vm.table = {
            limit: 5,
            page: 1
        }
        vm.ftps = [];
        vm.btnDeleteReg = true;
        vm.btnEditReg = true;

        vm.onPaginate = listarFtps;
        vm.onSearch = search;
        vm.onSelected = onSelected;
        vm.editReg = editReg;
        vm.deleteReg = deleteReg;
        vm.addReg = addReg;
        vm.seeReg = seeReg;

        /* FUNCTIOS */
        listarFtps(vm.table.page, vm.table.limit);
        function listarFtps(page, limit){
            var url = 'listar/' + limit + '?page=' + page;
            vm.promise = FtpService.customGET(url).then(success);
        }

        function search(key)
        {
            if (key.length > 2 || key.length == 0) {
                var url = 'listar/' + vm.table.limit + '?page=' + vm.table.page + '&filter=' + key;
                vm.promise = FtpService.customGET(url).then(success);
            }
        }

        function seeReg(ev, data)
        {
			$mdDialog.show({
                controller: FormFtpController,
                templateUrl: 'views/app/ftp/form.tpls.html',
                parent: angular.element($document.body),
                targetEvent: ev,
                clickOutsideToClose:true,
                locals : {
                    data: data.selected[0],
                    title: 'Ver',
                    readOnly: true
                }
            }).then(function() {
                return;
            }, function() {
                return;
            });
        }

        function addReg(ev)
        {
            $mdDialog.show({
                controller: FormFtpController,
                templateUrl: 'views/app/ftp/form.tpls.html',
                parent: angular.element($document.body),
                targetEvent: ev,
                clickOutsideToClose:true,
                locals : {
                    data: {},
                    title: 'Novo',
                    readOnly: false
                }
            }).then(function(answer) {
                if (answer == 'fechar') { return; }
                ToastService.show('Registro inserido com sucesso!');
                vm.table.page = 1;
                vm.promise = FtpService.post('criar', answer).then(success);
            }, function() {
                return;
            });
        }

        function editReg(ev, data)
        {
            var ftpsCopy = angular.copy(data.ftps);
            $mdDialog.show({
                controller: FormFtpController,
                templateUrl: 'views/app/ftp/form.tpls.html',
                parent: angular.element($document.body),
                targetEvent: ev,
                clickOutsideToClose:true,
                locals : {
                    data: data.selected[0],
                    title: 'Editar',
                    readOnly: false
                }
            }).then(function(answer) {
                if (answer == 'fechar') {
                    return;
                }
                ToastService.show('Registro atualizado com sucesso!');
                vm.table.page = 1;
                vm.promise = FtpService.post('atualizar', answer).then(success);
            }, function() {
                vm.ftps = ftpsCopy;
                return;
            });
        }

        function deleteReg(ev)
        {
            // Appending dialog to document.body to cover sidenav in docs app
            var confirm = $mdDialog.confirm()
                .title('Você realmente deseja remover este Registro?')
                .textContent('Os registro são desabilitados através do Campo "fl_ativo" no DB.')
                .ariaLabel('Remover')
                .targetEvent(ev)
                .ok('Remover')
                .cancel('Cancelar');
            $mdDialog.show(confirm).then(function() {
                ToastService.show('Registro desativado com sucesso!');
                vm.table.page = 1;
                vm.promise = FtpService.post('desativar', vm.selected).then(success);
            }, function() {
                return;
            });
        }

        function onSelected()
        {
            if (vm.selected.length > 0) {
                vm.btnDeleteReg = false;
            } else {
                vm.btnDeleteReg = true;
            }
            if (vm.selected.length == 1) {
                vm.btnEditReg = false;
            } else {
                vm.btnEditReg = true;
            }
        }

        function success(response)
        {
            vm.ftps = response;
            vm.promise = false;
        }

	}

    function FormFtpController($scope, $mdDialog, data, title, readOnly) {
        $scope.readOnly = readOnly;
        $scope.novoFtp = data;
        $scope.title = title;
        $scope.hide = function() {
            $mdDialog.hide();
        };
        $scope.cancel = function() {
            $mdDialog.cancel();
        };
        $scope.answer = function(answer) {
            $mdDialog.hide(answer);
        };
    }

})();
