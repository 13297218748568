(function(){
    "use strict";

    angular.module('app.services').factory('AuthService', AuthService);

    function AuthService(API){
        var service = {
            isAuth: API.one('isAuth'),
            auth: API.one('auth')
        }
        return service;
    }

})();
