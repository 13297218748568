(function(){
    "use strict";

    angular.module('app.services').factory('DatabaseService', DatabaseService);

    function DatabaseService(API){
        return API.one('database');
    }

})();
