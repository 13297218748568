(function(){
	"use strict";

	angular.module('app.routes').config(function($stateProvider, $urlRouterProvider){

		var getView = function getView(viewName){
			return './views/app/' + viewName + '/' + viewName + '.html';
		};
        var authView = function authView(viewName){
			return './views/app/auth/' + viewName + '/' + viewName + '.html';
		};

		$urlRouterProvider.otherwise('/');

		$stateProvider
            .state('auth', {
                abstract: true,
				views: {
					header: {},
					footer: {
						templateUrl: getView('footer')
					},
					main: {}
				}
            })
            .state('auth.criar', {
				url: '/criar',
				data: {},
				views: {
					'main@': {
						templateUrl: authView('criar')
					}
				}
			})
            .state('auth.login', {
				url: '/login',
				data: {},
				views: {
					'main@': {
						templateUrl: authView('login')
					}
				}
			})
			.state('app', {
				abstract: true,
				views: {
					header: {
						templateUrl: getView('header')
					},
					footer: {
						templateUrl: getView('footer')
					},
					main: {}
				},
                resolve: {
                    auth:auth
                }
			})
			.state('app.landing', {
				url: '/',
				data: {},
				views: {
					'main@': {
						templateUrl: getView('landing')
					}
				},
                resolve: {
                    auth:auth
                }
			})
            .state('app.diversos', {
                url: '/diversos',
                data: {},
                views: {
                    'main@': {
                        templateUrl: getView('diversos')
                    }
                },
                resolve: {
                    auth:auth
                }
            })
            .state('app.ftp', {
                url: '/ftp',
                data: {},
                views: {
                    'main@': {
                        templateUrl: getView('ftp')
                    }
                },
                resolve: {
                    auth:auth
                }
            })
            .state('app.database', {
                url: '/database',
                data: {},
                views: {
                    'main@': {
                        templateUrl: getView('database')
                    }
                },
                resolve: {
                    auth:auth
                }
            })
            .state('app.users', {
                url: '/users',
                data: {},
                views: {
                    'main@': {
                        templateUrl: getView('users')
                    }
                },
                resolve: {
                    auth:auth
                }
            })
            .state('app.user', {
                url: '/user',
                data: {},
                views: {
                    'main@': {
                        templateUrl: getView('user')
                    }
                },
                resolve: {
                    auth:auth
                }
            });

            function auth(AuthService, $state){
				AuthService.isAuth.get().then(function(){
                   return;
                }, function(){
                   return $state.go('auth.login');
                });
            }

	});
})();
