(function() {
	"use strict";

	angular.module('app.controllers').controller('DatabaseController', DatabaseController);

	function DatabaseController(DatabaseService, $mdDialog, ToastService, $document) {
		var vm = this;
		vm.angular_description = 'Faça uma busca no campo abaixo para destacar o que deseja.';
        vm.selected = [];
        vm.table = {
            limit: 5,
            page: 1
        }
        vm.dbs = [];
        vm.btnDeleteReg = true;
        vm.btnEditReg = true;
        vm.onPaginate = listarDbs;
        vm.onSearch = search;
        vm.onSelected = onSelected;
        vm.editReg = editReg;
        vm.deleteReg = deleteReg;
        vm.addReg = addReg;
        vm.seeReg = seeReg;
        
        /* FUNCTIOS */
        listarDbs(vm.table.page, vm.table.limit);
        function listarDbs(page, limit)
        {
            var url = 'listar/' + limit + '?page=' + page;
            vm.promise = DatabaseService.customGET(url).then(success);
        }
        function search(key)
        {
            if (key.length > 2 || key.length == 0) {
                var url = 'listar/' + vm.table.limit + '?page=' + vm.table.page + '&filter=' + key;
                vm.promise = DatabaseService.customGET(url).then(success);
            }
        }
        function seeReg(ev, data)
        {
            var locals = {
                    data: data.selected[0],
                    title: 'Ver',
                    readOnly: true
                };
            $mdDialog.show({
                controller: formDatabaseController,
                templateUrl: 'views/app/database/form.tpls.html',
                parent: angular.element($document.body),
                targetEvent: ev,
                clickOutsideToClose:true,
                locals : locals 
            }).then(function() {
                return;
            }, function() {
                return;
            });
        }
        
        function addReg(ev)
        {
            var locals = {
                    data: {},
                    title: 'Novo',
                    readOnly: false
                };
            $mdDialog.show({
                controller: formDatabaseController,
                templateUrl: 'views/app/database/form.tpls.html',
                parent: angular.element($document.body),
                targetEvent: ev,
                clickOutsideToClose:true,
                locals : locals 
            }).then(function(answer) {
                if (answer == 'fechar') { return; }
                ToastService.show('Registro inserido com sucesso!');
                vm.table.page = 1;
                vm.promise = DatabaseService.post('criar', answer).then(success);
            }, function() {
                return;
            });
        }
        function editReg(ev, data)
        {
            var dbsCopy = angular.copy(data.dbs),
                locals = {
                            data: data.selected[0],
                            title: 'Editar',
                            readOnly: false
                        };
            $mdDialog.show({
                controller: formDatabaseController,
                templateUrl: 'views/app/database/form.tpls.html',
                parent: angular.element($document.body),
                targetEvent: ev,
                clickOutsideToClose:true,
                locals : locals
            }).then(function(answer) {
                if (answer == 'fechar') {
                    return; 
                }
                ToastService.show('Registro atualizado com sucesso!');
                vm.table.page = 1;
                vm.promise = DatabaseService.post('atualizar', answer).then(success);
            }, function() {
                vm.dbs = dbsCopy;
                return;
            });
        }
        function deleteReg(ev)
        {
            // Appending dialog to document.body to cover sidenav in docs app
            var confirm = $mdDialog.confirm()
                .title('Você realmente deseja remover este Registro?')
                .textContent('Os registro são desabilitados através do Campo "fl_ativo" no DB.')
                .ariaLabel('Remover')
                .targetEvent(ev)
                .ok('Remover')
                .cancel('Cancelar');
            $mdDialog.show(confirm).then(function() {
                ToastService.show('Registro desativado com sucesso!');
                vm.table.page = 1;
                vm.promise = DatabaseService.post('desativar', vm.selected).then(success);
            }, function() {
                return;
            });
        }
        function onSelected()
        {
            if (vm.selected.length > 0) {
                vm.btnDeleteReg = false;
            } else {
                vm.btnDeleteReg = true;
            }
            if (vm.selected.length == 1) {
                vm.btnEditReg = false;
            } else {
                vm.btnEditReg = true;
            }
        }
        
        function success(response)
        {
            vm.dbs = response;
            vm.promise = false;
        }
	}
    
    function formDatabaseController($scope, $mdDialog, data, title, readOnly) 
    {
        $scope.readOnly = readOnly;
        $scope.novoDb = data;
        $scope.title = title;
        $scope.hide = function() {
            $mdDialog.hide();
        };
        $scope.cancel = function() {
            $mdDialog.cancel();
        };
        $scope.answer = function(answer) {
            $mdDialog.hide(answer);
        };
    }
})();